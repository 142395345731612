'use client'

import React, { ReactNode } from 'react'

import BackIcon from '@mui/icons-material/KeyboardBackspace'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import { useRouter } from 'next/navigation'

export default function ExternalPageBackButton({ back, ...props }: { back: ReactNode } & Pick<IconButtonProps, 'sx'>) {
  const router = useRouter()

  if (typeof back === 'boolean') {
    return (
      back && (
        <IconButton aria-label="Back" sx={{ position: 'absolute', left: 0 }} onClick={() => router.back()} {...props}>
          <BackIcon />
        </IconButton>
      )
    )
  }

  if (typeof back === 'string') {
    return (
      !!back && (
        <IconButton aria-label="Back" href={back} sx={{ position: 'absolute', left: 0 }} {...props}>
          <BackIcon />
        </IconButton>
      )
    )
  }

  return back
}
