export function generateNonce() {
  if (window?.crypto?.subtle) {
    return btoa(String.fromCharCode(...Array.from(window.crypto.getRandomValues(new Uint8Array(16))))).slice(0, -2)
  }

  // Avoid very short output
  let output = Math.random().toString(36).slice(2)
  while (output.length < 22) {
    output += Math.random().toString(36).slice(2)
  }

  return output.substring(0, 22)
}

export default {}
