'use client'

import { useEffect, useState } from 'react'

import { useSearchParams } from 'next/navigation'

export default function useSanitisedTarget() {
  const searchParams = useSearchParams()
  const target = searchParams?.get('target')

  const [origin, setOrigin] = useState<string>()

  useEffect(() => {
    setOrigin(window.location.origin)
  }, [])

  // Example input that this protects us against: `?target=//website.com`
  const originURL = origin ? new URL(origin) : undefined
  const targetURL = origin && target ? new URL(target, origin) : undefined

  const sourceAndDestinationOriginMatch = originURL && targetURL && originURL.origin === targetURL.origin

  return sourceAndDestinationOriginMatch ? targetURL.pathname : undefined
}
