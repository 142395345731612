'use client'

import { useCallback } from 'react'

import { useGetAuthEndpointsQuery } from 'redesignStore/api/loginApi/loginApi'
import { generateNonce } from 'redesignStore/api/loginApi/utils/auth'
import usePkceChallenge from 'redesignStore/api/loginApi/utils/usePkceChallenge'
import useSanitisedTarget from 'redesignStore/api/loginApi/utils/useSanitisedTarget'

export default function useAuthEndpoints() {
  const { data: authEndpoints, isFetching, isError } = useGetAuthEndpointsQuery()
  const sanitisedTarget = useSanitisedTarget()
  const pkceChallenge = usePkceChallenge()

  const generateRedirectURI = useCallback(
    (
      request: (
        | { type: 'sessionToken'; sessionToken: string }
        | { type: 'sso'; idpId: string | undefined }
        | { type: 'idp-initiated-sso' }
      ) & { state?: Record<string, unknown> }
    ): string => {
      if (!authEndpoints || !pkceChallenge) {
        throw new Error(`Unable to generate redirect URI as authEndpoints has not loaded`)
      }

      const nonce = generateNonce()

      // If we're running with create and isda create domains, always direct to the create domain at login time
      const redirectURI =
        process.env.NEXT_PUBLIC_CREATE_DOMAIN && process.env.NEXT_PUBLIC_ISDA_CREATE_DOMAIN
          ? `https://${process.env.NEXT_PUBLIC_CREATE_DOMAIN}/callback`
          : `${window.location.origin}/callback`

      const state = btoa(
        JSON.stringify({
          ...window.location,
          ...request.state,
          ...(sanitisedTarget && { from: { pathname: sanitisedTarget } }),
        })
      )

      const requestInfo =
        request.type === 'sessionToken'
          ? `&sessionToken=${encodeURIComponent(request.sessionToken)}`
          : request.type === 'sso'
            ? `&idp=${encodeURIComponent(request.idpId ?? '')}`
            : ''

      // Store verifier for use during code exchange
      window.sessionStorage.setItem('PKCE_Verifier', pkceChallenge.verifier)

      return `${authEndpoints.auth.oauthRefresh}${requestInfo}&nonce=${encodeURIComponent(nonce)}&redirect_uri=${encodeURIComponent(redirectURI)}&state=${encodeURIComponent(state)}&code_challenge_method=S256&code_challenge=${encodeURIComponent(pkceChallenge.challenge)}`
    },
    [authEndpoints, pkceChallenge, sanitisedTarget]
  )

  return {
    authEndpoints,
    isFetching,
    isError,
    generateRedirectURI,
  }
}
